import React, { MouseEventHandler, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { breakpoints, colors, eases } from "../../../styles/variables";

const ButtonContainer = styled.a`
  padding: 12px 15px;
  min-width: 63px;
  cursor: pointer;
  flex: 1;
  height: 80px;
  transition: all 0.2s ease-out;
  position: relative;
  animation: scale 0.2s linear 1;
  transform-origin: left;

  // Default is blue theme
  border: 4px solid ${colors.teal};
  box-shadow: 4px 4px ${colors.teal};

  //Scales Platform containers on render
  @keyframes scale {
    0% {
      transform: scaleX(0.65);
    }

    100% {
      transform: scaleX(1);
    }
  }

  &.platformIcon-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.button-group2 {
    min-width: 100%;
  }

  &.button-group3,
  &.button-group4 {
    min-width: 118px;
  }

  &.button-group5,
  &.button-group6 {
    min-width: 117px;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(30deg, ${colors.green} 50%, ${colors.white} 51%);
    background-size: 375% 100%;
    background-position: 0 0;
    transition: background-position 1s ${eases.inOut};
  }

  .logo,
  svg {
    max-width: 100%;
    max-height: 48px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    opacity: 0;
    animation: logo 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
  }
  //fade in logos after scale render
  @keyframes logo {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  path {
    fill: #fff;
    transition: fill 0.3s ease-out;
  }

  &:hover,
  &.active {
    box-shadow: 2px 2px ${colors.smoke};
    transform: translate(2px, 2px);
    path {
      fill: ${colors.black};
    }
    .background {
      background-position: 100% 0;
    }
  }

  //Had to write a ton of media queries for desired design outcome. Always two rows, regardless of total number of platforms. Use case up to 6 platforms.
  @media (max-width: ${breakpoints.laptop}) {
    &.button-group3,
    &.button-group4 {
      min-width: 147px;
    }
    &.button-group5,
    &.button-group6 {
      min-width: 90px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    min-width: 150px;
    &.button-group3,
    &.button-group4 {
      min-width: 262px;
    }

    &.button-group5,
    &.button-group6 {
      min-width: 190px;
    }
  }

  @media only screen and (max-width: 915px) {
    &.button-group5,
    &.button-group6 {
      min-width: 150px;
    }
  }

  @media only screen and (max-width: 859px) {
    &.button-group4 {
      min-width: 176px;
    }
  }

  @media only screen and (max-width: 822px) {
    &.button-group3 {
      min-width: 167px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100px;
    padding: 9px 0px;
    .background {
      background-size: 350% 100%;
    }
    .logo,
    svg {
      max-width: 92%;
    }
    &.button-group3,
    &.button-group4 {
      min-width: 208px;
    }
    &.button-group5 {
      min-width: 150px;
    }
  }

  @media only screen and (max-width: 537px) {
    &.button-group4 {
      min-width: 150px;
    }
  }

  @media only screen and (max-width: 595px) {
    &.button-group3 {
      min-width: 150px;
    }
    &.button-group5 {
      min-width: 107px;
    }
    &.button-group6 {
      min-width: 106px;
    }
  }

  @media only screen and (max-width: 466px) {
    &.button-group3 {
      min-width: 107px;
    }
    &.button-group5,
    &.button-group6 {
      min-width: 76px;
    }
  }

  @media only screen and (max-width: 422px) {
    &.button-group4 {
      min-width: 126px;
    }
  }

  @media only screen and (max-width: 541px) {
    min-width: 132px;
  }

  @media only screen and (max-width: 386px) {
    min-width: 126px;
  }

  @media (max-width: ${breakpoints.mobileS}) {
    min-width: 122px;
  }
`;

interface Props {
  console: string;
  selected?: string;
  url: string;
  callback: Function;
  svg: string;
  buttonLength: number;
}

export default function ButtonConsole(props: Props) {
  const selfRef = useRef(null);
  const disabled = !props.url;

  const url = disabled ? "#" : props.url;
  const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };
  const iconClass = disabled ? "platformIcon-disabled" : "platformIcon";
  const className = `${iconClass} button-group${props.buttonLength}`;

  const consoleSlug = props.console.toLowerCase().replace(/\W+/g, "-");
  const id = `platform-link-${consoleSlug}`;

  useEffect(() => {
    const q = gsap.utils.selector(selfRef);
    gsap.fromTo(q(".logo"), { opacity: 0 }, { opacity: 1, duration: 3 });
  }, []);

  return (
    <ButtonContainer href={url} onClick={onClick} target="_blank" id={id} className={className} ref={selfRef}>
      <div className="background"></div>
      <div className="logo" dangerouslySetInnerHTML={{ __html: props.svg }}></div>
    </ButtonContainer>
  );
}
