import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import { ContentstackImg } from "@riotgames/wwpub-components";
import { breakpoints } from "../../../styles/variables";

const MobileOSButtonContainer = styled.a`
  .mobile-logo,
  image {
    max-width: 135px;
    height: auto;
    opacity: 0;
    animation: mobile-logo 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;

    @media (max-width: ${breakpoints.mobile}) {
      max-height: 38px;
    }
  }

  @keyframes mobile-logo {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes mobile-logo {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
interface Props {
  console: string;
  selected?: string;
  url: string;
  image: string;
  callback: Function;
}

export class MobileOSButtonConsole extends React.Component<Props> {
  render() {
    const disabled = !this.props.url;

    const url = disabled ? "#" : this.props.url;
    const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
      if (disabled) {
        e.preventDefault();
      }
    };
    const className = disabled ? "platformIcon-disabled" : "platformIcon";

    const consoleSlug = this.props.console.toLowerCase().replace(/\W+/g, "-");
    const id = `platform-link-${consoleSlug}`;

    return (
      <MobileOSButtonContainer href={url} onClick={onClick} target="_blank" id={id} className={className}>
        <ContentstackImg className="mobile-logo" image={{ url: this.props.image }} />
      </MobileOSButtonContainer>
    );
  }
}
